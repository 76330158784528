import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"

// import required modules
import { FreeMode, Scrollbar, Mousewheel, Pagination } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/scrollbar"
import "swiper/css/mousewheel"
import "swiper/css/pagination"

const slider = props => {
  return (
    <Swiper
      style={{
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        minWidth: "60%",
      }}
      direction={"horizontal"}
      spaceBetween={30}
      slidesPerView={1}
      freemode={"true"}
      mousewheel={{
        releaseOnEdges: true,
      }}
      pagination={{
        clickable: true,
      }}
      scrollbar={true}
      modules={[FreeMode, Scrollbar, Mousewheel, Pagination]}
    >
      {React.Children.toArray(
        props.content.map(item => {
          return (
            <SwiperSlide style={{ paddingBottom: "1.75em" }}>
              {item}
            </SwiperSlide>
          )
        })
      )}
    </Swiper>
  )
}

export default slider
