import * as React from "react"
import Link from "@mui/material/Link"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import FlexRow from "components/containers/flexRow"
import FlexColumn from "components/containers/flexColumn"
import { EnquiriesButton } from "components/buttons/EnquiriesButton"

import Seo from "../components/seo"
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined"
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined"
import ContactForm from "components/ContactForm"
import AOS from "aos"
import "aos/dist/aos.css"
import "components/animate.css"
import Slider from "components/slider"

/* ----------------------------------------------------------------
********************************

Homepage of the Slick Blue Dragon website. Layout with Material UI and Flexbox.

********************************
-------------------------------------------------------------------*/

const IndexPage = () => {
  const slideItems = [
    <>
      <Typography variant="h2" tabIndex={0} sx={{ marginTop: 0 }}>
        Website Structure
      </Typography>
      <Typography variant="body1">
        The structure of the website and pages help Google to read the content.
        Content that can't be found or is read in the wrong order is as good as
        no content.
      </Typography>
    </>,
    <>
      <Typography variant="h2" tabIndex={0}>
        Appropriate Development Technology
      </Typography>
      <Typography variant="body1">
        The technology used for your website will depend on budget but also the
        needs of your application. A low code technology may suit a simple
        project but not necessarily a complex website visited by thousands of
        users.
      </Typography>
    </>,
    <>
      <Typography variant="h2" tabIndex={0}>
        Maintenance and Security Tradeoff
      </Typography>
      <Typography variant="body1">
        The maintenance and security costs can vary. A small WordPress site will
        require regular updates whereas a custom site that doesn't need content
        changes may save on the maintenance for the bigger initial project cost.
      </Typography>
    </>,
  ]

  React.useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    })
  }, [])
  return (
    <>
      <Container
        maxWidth="false"
        sx={{
          borderBottom: "0.75em solid primary.accentDark",
          maxWidth: "90%",
        }}
      >
        <FlexRow sx={{ justifyContent: "center" }}>
          <FlexColumn
            sx={{
              // alignItems: "center",
              paddingTop: "2em",
              paddingBottom: "3.1em",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                "&.MuiTypography-h1": {
                  fontSize: "2.25rem",
                  fontWeight: "500",
                },
              }}
              tabIndex={0}
            >
              Providing Effective Website Development For Your Needs
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { md: "3rem" }, paddingTop: "1em" }}
              tabIndex={0}
            >
              Whether you could do with a new website or improving an existing
              site we can add value to your organisation.{" "}
            </Typography>
          </FlexColumn>
        </FlexRow>
      </Container>
      <Box
        sx={{
          backgroundColor: "primary.offWhite",
          paddingTop: "0.5em",
          borderColor: "primary.accentLight",
          borderTopStyle: "inset",
          borderBottomStyle: "inset",
          borderTopWidth: "0.75em",
          borderBottomWidth: "0.75em",
          paddingBottom: "1.75em",
          paddingTop: "1.75em",
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            maxWidth: "90%",
          }}
        >
          <FlexRow
            sx={[
              {
                // borderBottom: "0.75em solid primary.accentDark",
                flexWrap: { xs: "wrap", sm: "nowrap" },
                justifyContent: "center",
                // paddingBottom: "0.25em",
              },
            ]}
          >
            <FlexColumn sx={{ alignItems: "center" }}>
              <PhoneAndroidOutlinedIcon
                tabIndex={0}
                sx={{ fontSize: "60px" }}
              ></PhoneAndroidOutlinedIcon>
              <Typography
                sx={{ paddingTop: "0.5em" }}
                variant="body1"
                tabIndex={0}
              >
                Having the right solution is important. We can discuss the most
                appropriate web solution for your organisation whether that be
                WordPress, bespoke development or both.
              </Typography>
            </FlexColumn>
            <FlexColumn sx={{ alignItems: "center" }}>
              <SearchOutlinedIcon
                sx={{ fontSize: "60px" }}
              ></SearchOutlinedIcon>
              <Typography sx={{ paddingTop: "0.5em" }} variant="body1">
                It’s important to have a website structure that gives it the
                best chance of being found online through search regardless of
                content.
              </Typography>
            </FlexColumn>
            <FlexColumn sx={{ alignItems: "center" }}>
              <WorkOutlineOutlinedIcon
                sx={{ fontSize: "60px" }}
              ></WorkOutlineOutlinedIcon>
              <Typography sx={{ paddingTop: "0.5em" }} variant="body1">
                Having a website can help promote your organisation. Tell
                potential customers and referrals what you do on your terms.
              </Typography>
            </FlexColumn>
          </FlexRow>
        </Container>
      </Box>
      <Box
        sx={{
          paddingTop: "0.5em",
          borderColor: "primary.accentLight",
          // borderTopStyle: "inset",
          // borderBottomStyle: "outset",
          borderTopWidth: "0.75em",
          borderBottomWidth: "0.75em",
          paddingBottom: "0.5em",
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            maxWidth: "90%",
          }}
        >
          <FlexRow
            sx={{
              justifyContent: "center",
              paddingBottom: 0,
              paddingTop: "1.5em",
            }}
          >
            <Typography variant="h1" tabIndex={0}>
              Why does this matter to you? (swipe/ scroll)
            </Typography>
          </FlexRow>
          <FlexRow sx={{ paddingTop: 0, paddingBottom: "0.5em" }}>
            <Slider style={{ height: "50%" }} content={slideItems}>
              {" "}
              tabIndex={0}
            </Slider>
          </FlexRow>
          <FlexRow sx={{ justifyContent: "flex-end", padding: 0 }}>
            <KeyboardDoubleArrowDownOutlinedIcon
              className="arrow-animate"
              sx={{
                fontSize: "2.5rem",
                display: { xs: "none", md: "inline" },
              }}
            />
            <Typography
              sx={{
                display: { xs: "none", md: "inline" },
                "& .MuiTypography-body2": { marginLeft: 0 },
              }}
              variant="body2"
            >
              Get in touch
            </Typography>
          </FlexRow>
        </Container>
      </Box>

      <Box
        sx={{
          paddingBottom: "1.37em",
          paddingTop: "2.75em",
          backgroundColor: "secondary.main",
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            maxWidth: "700px",
            width: { xs: "95%", md: "80%" },
            padding: "1em 5%",
            backgroundColor: "primary.offWhite",
            border: "0.25em solid black",
            borderRadius: "0.5em",
            boxShadow: "0.5em 0.5em 0.75em",
          }}
        >
          <ContactForm />
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "secondary.main" }}>
        <Container maxWidth="false" sx={{ maxWidth: "700px", width: "100%" }}>
          <FlexRow sx={{ justifyContent: "center", paddingTop: "0.5em" }}>
            <Link underline="none" sx={{}} href="/contact">
              <EnquiriesButton />
            </Link>
          </FlexRow>
        </Container>
      </Box>
    </>
  )
}

export default IndexPage

export function Head() {
  return (
    <Seo
      title="Peterborough UK Freelance Web Development - Spark Your Curiosity"
      description="Slick Blue Dragon supports your creative business and helps you discover what's possible on the web with WordPress or bespoke websites."
    />
  )
}
